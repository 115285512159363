<script setup lang="ts">
import type { BreadcrumbLink } from '#ui/types';
import { subject } from '@casl/ability';
import AddDataSourceModal from '~/components/modals/AddDataSourceModal.vue';
import AddNewFolderModal from '~/components/modals/AddNewFolderModal.vue';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import EmptyState from '~/components/table/EmptyState.vue';
import { useAppAbility } from '~/composables/useAppAbility';
import { fileFormatToIcon, sourceToIcon } from '~/util/constants';

const { can } = useAppAbility();

const modal = useModal();

const openAddDataSourceModal = () => {
  modal.open(AddDataSourceModal, {
    refresh: refresh,
    localOnly: !!dataSourceId.value,
  });
};

const openNewFolderModal = () => {
  modal.open(AddNewFolderModal, {
    type: 'file',
  });
};

const dataSourcesStore = useDataSourcesStore();
const workspaceStore = useWorkspaceStore();

const { workspace, subscription } = storeToRefs(workspaceStore);
const { folders, currentFolder } = storeToRefs(dataSourcesStore);

const dataSourceId = useRouteParams('dataSourceId');

const page = ref(1);
const pageSize = ref(10);

const liveQuery = ref('');
const searchQuery = ref('');

// When a new dataSourceId occurs (IE a folder or breadcrumb is clicked), we need to reset `page`
// When a new search occurs we need to reset `page`
watch([dataSourceId, searchQuery], () => {
  page.value = 1;
});

const { data, pending, refresh } = await useAsyncData(
  `data/${dataSourceId.value}/${searchQuery.value}`,
  () =>
    dataSourcesStore.fetchDataSources(
      String(dataSourceId.value),
      searchQuery.value,
    ),
  { watch: [dataSourceId, searchQuery], lazy: true },
);

const { parent, grandParent, count } = storeToRefs(dataSourcesStore);

const totalPageCount = computed(() =>
  dataSourceId.value ? count.value : count.value + folders.value?.length,
);

// If there is a dataSourceId, we're in Notion or Google so do not show folder items
const folderItems = computed(() =>
  dataSourceId.value === '' && !searchQuery.value
    ? folders.value?.map((item) => ({
        name: item.name,
        folderId: item.id,
      })) || []
    : [],
);

const dataSourceItems = computed(
  () =>
    data.value?.map((item) => ({
      ...item,
      readableSize: readableFileSize(item.fileSize),
      formattedDate: item.lastSync
        ? formatDate(item.lastSync)
        : formatDate(item.updatedAt),
    })) || [],
);

const rows = computed(() => [...folderItems.value, ...dataSourceItems.value]);

const paginatedRows = computed(() => {
  const start = (page.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return rows.value.slice(start, end);
});

const debouncedSearch = useDebounceFn(
  (query: string) => {
    searchQuery.value = query;
  },
  300,
  { maxWait: 500 },
);

// Breadcrumbs
const links: BreadcrumbLink[] = computed(() => {
  const links = [
    {
      label: 'Home',
      icon: 'i-ph-house',
      iconActive: 'i-ph-house-fill',
      to: '/data-sources',
    },
  ];

  if (currentFolder.value && currentFolder.value.name) {
    links.push({
      label: currentFolder.value.name,
      icon: 'i-ph-folder-simple',
      iconActive: 'i-ph-folder-simple-fill',
      to: `/data-sources/${currentFolder.value.id}`,
    });
  }

  if (grandParent.value && grandParent.value.name) {
    if (grandParent.value.parentId) {
      const greatGrandParent: BreadcrumbLink = {
        label: '...',
      };
      links.push(greatGrandParent);
    }

    links.push({
      label: grandParent.value.name,
      icon: 'i-ph-google-drive-logo',
      iconActive: 'i-ph-google-drive-logo-fill',
      to: `/data-sources/${grandParent.value.id}`,
    });
  }

  if (parent.value && parent.value.name) {
    links.push({
      label: parent.value.name,
      icon: 'i-ph-google-drive-logo',
      iconActive: 'i-ph-google-drive-logo-fill',
      to: `/data-sources/${parent.value.id}`,
    });
  }

  return links;
});

const formatDate = (date: string) => {
  const navigatorLanguage = navigator ? navigator.language : 'en-US';
  return `${new Date(date).toLocaleDateString(navigatorLanguage, {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  })} at ${new Date(date).toLocaleTimeString(navigatorLanguage, {
    hour: 'numeric',
    minute: '2-digit',
  })}`;
};

const readableFileSize = (sizeInBytes = 0) => {
  if (sizeInBytes === 0) return '';
  let i = -1;
  const byteUnits = [' KB', 'MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    sizeInBytes = sizeInBytes / 1000;
    i++;
  } while (sizeInBytes > 1000);
  return `${Math.max(sizeInBytes, 0.1).toFixed(1)} ${byteUnits[i]}`;
};

// TODO: We can add `sortable`, but need to pass the sort to Carbon, out of scope for now
const columns = [
  {
    key: 'fileFormat',
    label: '',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'source',
    label: 'Source',
  },

  {
    key: 'readableSize',
    label: 'Size',
  },
  {
    key: 'formattedDate',
    label: 'Last updated',
    direction: 'desc' as const,
  },
  {
    key: 'actions',
  },
];

const getFileFormatIcon = (row) => {
  return row.fileFormat === undefined
    ? 'i-ph-folder-simple-fill'
    : fileFormatToIcon[row.fileFormat];
};

const getSourceIcon = (row) => {
  return Object.keys(sourceToIcon).includes(row.source)
    ? sourceToIcon[row.source]
    : '';
};

const handleDelete = async (row) => {
  if (row.folderId) {
    modal.open(ConfirmModal, {
      type: 'folder',
      action: 'delete',
      message:
        'Are you sure you want to delete this folder? All folder contents will also be deleted.',
      isDangerous: true,
      async onConfirm() {
        await dataSourcesStore.deleteFolder(row.folderId);
        modal.close();
      },
    });
  } else {
    await dataSourcesStore.deleteById(row.id);

    setTimeout(refresh, 200);
  }
};

const items = (row) => [
  [
    {
      label: 'Delete',
      icon: 'i-ph-trash',
      disabled: !can('editFiles', subject('Workspace', workspace.value)),
      click: () => handleDelete(row),
    },
  ],
];

const select = async (row) => {
  if (row.folderId) {
    navigateTo({ path: `/data-sources/${row.folderId}` });
  } else {
    const index = data.value.findIndex((item) => item.id === row.id);
    const dataSource = data.value[index];
    if (dataSource.fileMetadata.isFolder) {
      navigateTo({ path: `/data-sources/${row.id}` });
    } else {
      const url = await dataSourcesStore.getUrlByFileId(dataSource.id);
      if (url) {
        window.open(url);
      }
    }
  }
};
</script>
<template>
  <NuxtLayout name="dashboard">
    <div class="flex justify-between">
      <p class="main-title">Data Sources</p>
      <!-- New folder/file button -->
      <div class="flex space-x-2">
        <UTooltip
          v-if="!dataSourceId"
          :prevent="can('editFiles', subject('Workspace', workspace))"
          text="Uploads disabled. Contact your workspace admin."
        >
          <UButton
            :disabled="!can('editFiles', subject('Workspace', workspace))"
            variant="solid"
            color="white"
            size="xl"
            @click.stop="openNewFolderModal"
          >
            New Folder
          </UButton>
        </UTooltip>
        <UTooltip
          v-if="currentFolder || !dataSourceId"
          :prevent="can('editFiles', subject('Workspace', workspace))"
          text="Uploads disabled. Contact your workspace admin."
        >
          <UButton
            :disabled="!can('editFiles', subject('Workspace', workspace))"
            icon="i-ph-plus"
            variant="solid"
            size="xl"
            @click.stop="openAddDataSourceModal"
          >
            New
          </UButton>
        </UTooltip>
      </div>
    </div>

    <!-- Current folder bread crumb -->
    <UBreadcrumb
      divider="/"
      :links="links"
      :ui="{ ol: 'justify-start' }"
      class="my-m"
    >
      <template #icon="{ link, index, isActive }">
        <UIcon
          :name="isActive ? link.iconActive : link.icon"
          :alt="(index + 1).toString()"
          class="text-lg"
          :ui="{
            background: isActive
              ? 'bg-primary-500 dark:bg-primary-400'
              : undefined,
            placeholder: isActive
              ? 'text-white dark:text-gray-900'
              : !!link.to
                ? 'group-hover:text-gray-700 dark:group-hover:text-gray-200'
                : '',
          }"
        />
      </template>
    </UBreadcrumb>
    <!-- Search is outside the table itself because we query the API, not just the current contents of the table-->
    <UInput
      v-model="liveQuery"
      :loading="pending"
      icon="i-ph-magnifying-glass-bold"
      color="white"
      variant="outline"
      placeholder="Search the contents of your documents"
      autocomplete="off"
      :ui="{ icon: { trailing: { pointer: '' } } }"
      @input="debouncedSearch($event.target.value)"
    >
      <template #trailing>
        <UButton
          v-show="liveQuery !== ''"
          color="gray"
          variant="link"
          icon="i-ph-x"
          :padded="false"
          @click="liveQuery = ''"
        />
      </template>
    </UInput>
    <transition name="grow-fade" mode="out-in">
      <div>
        <UTable
          :loading="pending"
          :columns="columns"
          :rows="paginatedRows"
          @select="select"
        >
          <template #empty-state>
            <empty-state icon-name="i-ph-cloud">
              <p class="text-lg font-bold">Connect your files to AI</p>
              <p class="text-lg dimmed">
                Upload and sync your documents to give AI workflows access to
                your knowledge
              </p>
            </empty-state>
          </template>
          <template #fileFormat-data="{ row }">
            <UIcon
              :name="getFileFormatIcon(row)"
              class="text-2xl"
              :class="{
                'text-accent': row.fileFormat !== undefined, // Folders have an undefined fileFormat
              }"
            />
          </template>
          <template #source-data="{ row }">
            <UIcon :name="getSourceIcon(row)" />
          </template>
          <template #actions-data="{ row }">
            <UDropdown :items="items(row)" @click.stop>
              <UButton color="gray" variant="ghost" icon="i-ph-dots-three" />
            </UDropdown>
          </template>
        </UTable>
        <div class="flex justify-center px-3 py-3.5">
          <UPagination
            v-model="page"
            :page-count="pageSize"
            :total="totalPageCount"
          />
        </div>
      </div>
    </transition>
  </NuxtLayout>
</template>
